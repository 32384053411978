import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import EditorAbstractDetailTable from "../components/part/editorAbstractDetailTable"
import EditorAbstractDetailReview from "../components/part/editorAbstractDetailReview"
import AbstractDetailModal from "../components/part/abstractDetailModal"

const EditorAbstractDetailPage = () => (
  <Layout>
    <SEO title="Submission" />
    <Banner title="Abstract Submission Editor"></Banner>
    <div className="content-wrap" style={{ marginTop: "32px" }}>
      <div className="container clearfix">
        <div className="row">
          <EditorAbstractDetailTable></EditorAbstractDetailTable>
          <EditorAbstractDetailReview></EditorAbstractDetailReview>
        </div>
        <AbstractDetailModal></AbstractDetailModal>
      </div>
    </div>
  </Layout>
)

export default EditorAbstractDetailPage

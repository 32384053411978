import { Button, Card, Empty } from "antd"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { DASHBOARD_EDITOR } from "../../constants/routes"
import { Files } from "../../redux/api"
import * as type from "../../constants/abstractStatus"

const EditorAbstractDetailReview = ({ state }) => {
  const [listReview, setListReview] = useState([])
  const [status, setStatus] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (!state.file.detail.id) return
      const rReview = await Files.review(state.file.detail.id)
      setListReview(rReview ? (await rReview.json()).reviews : [])
    }
    fetchData()
  }, [state.file.detail])

  const getActiveButtonClass = selected => {
    return selected === status ? " active" : null
  }

  const handleStatusChange = selected => {
    setStatus(selected)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const id = state.file.detail.id
    await Files.status({ id, status })
    setLoading(false)
    navigate(DASHBOARD_EDITOR)
  }

  return (
    <div className="col-md-4 pd-reg">
      <div className="fancy-title top-md title-border">
        <h4>Review</h4>
      </div>
      <div className="form-widget">
        <div className="form-result" />
        <div className="form-group mb-4">
          <label htmlFor="approved">Feedback</label>
          {listReview.map((r, i) => (
            <Card key={i} title={r.User.fullname} className="my-2" type="inner">
              {r.description ? r.description : <Empty />}
            </Card>
          ))}
        </div>
        <div className="form-group mb-4">
          <label htmlFor="result" className="mb-3">
            Result
          </label>
          <br />
          <button
            className={
              "d-flex btn btn-outline-danger w-100 px-3 my-2 font-weight-semibold ls0 nott" +
              getActiveButtonClass(type.REJECTED)
            }
            onClick={() => {
              handleStatusChange(type.REJECTED)
            }}
          >
            Rejected
          </button>
          <button
            className={
              "d-flex btn btn-outline-info w-100 px-3 my-2 font-weight-semibold ls0 nott" +
              getActiveButtonClass(type.REVISION_NEEDED)
            }
            onClick={() => {
              handleStatusChange(type.REVISION_NEEDED)
            }}
          >
            Need Revision
          </button>
          <button
            className={
              "d-flex btn btn-outline-success w-100 px-3 my-2 font-weight-semibold ls0 nott" +
              getActiveButtonClass(type.APPROVED)
            }
            onClick={() => {
              handleStatusChange(type.APPROVED)
            }}
          >
            Accepted
          </button>
        </div>
      </div>
      <div className="line line-sm mb-4" />
      <Button
        type={status ? "primary" : null}
        className="mr-0 mb-3 w-100"
        onClick={handleSubmit}
        loading={loading}
        disabled={status ? false : true}
      >
        UPDATE STATUS
      </Button>
      <button
        className="button button-circle button-border button-white button-reg mr-0 text-center"
        onClick={() => {
          navigate(DASHBOARD_EDITOR)
        }}
      >
        CANCEL
      </button>
    </div>
  )
}

export default connect(state => ({ state }))(EditorAbstractDetailReview)

import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { DASHBOARD_EDITOR } from "../../constants/routes"
import { startCase } from "lodash"
import { getBadgeStyling, renameStatus, templatePdf } from "../../utils/styling"
import { jsPDF } from "jspdf"
import { renderToString } from "react-dom/server"

const EditorAbstractDetailTable = ({ state }) => {
  const [form, setForm] = useState({})

  useEffect(() => {
    setForm(state.file.detail || {})
  }, [state.file.detail])

  const exportPdf = () => {
    const doc = new jsPDF("portrait", "px", "a4")
    const template = renderToString(templatePdf(form))
    doc.html(template, {
      callback: doc => doc.save(`abstract-${form.id}.pdf`),
      html2canvas: {},
      x: 10,
      y: 10,
    })
  }

  return (
    <div className="col-md-8">
      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="clearfix" id="abstract">
            <div className="row mb-1">
              <div className="col-md-6 mb-4">
                <button
                  className="button button-reg button-3d button-circle gradient-grey-orange mr-0 text-center mb-3 submit float-left"
                  onClick={() => navigate(DASHBOARD_EDITOR)}
                >
                  Back to Dashboard
                </button>
              </div>
              <div className="col-md-6 mb-4">
                <button
                  className="button button-reg button-3d button-circle gradient-grey-orange mr-0 mb-3 submit"
                  onClick={exportPdf}
                >
                  Download
                </button>
              </div>
            </div>
            <table className="table table-bordered mb-5">
              <tbody>
                <tr>
                  <th className="w-25">Title</th>
                  <td className="w-75">{form.title}</td>
                </tr>
                <tr>
                  <th className="w-25">Status</th>
                  <td>
                    <span className={getBadgeStyling(form.status)}>
                      {renameStatus(form.status)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th className="w-25">Author</th>
                  <td className="w-75">{form.author}</td>
                </tr>
                <tr>
                  <th className="w-25">Author's Affiliation</th>
                  <td className="w-75">
                    <ol className="ml-3 mb-1">
                      {form.author_affiliation
                        ? form.author_affiliation
                            .split("| ")
                            .map((a, i) => <li key={i}>{a}</li>)
                        : null}
                    </ol>
                  </td>
                </tr>
                <tr>
                  <th className="w-25">Category</th>
                  <td className="w-75">{startCase(form.category)}</td>
                </tr>
                <tr>
                  <th className="w-25">Background</th>
                  <td className="w-75">{form.background}</td>
                </tr>
                <tr>
                  <th className="w-25">Methods</th>
                  <td className="w-75">{form.methods}</td>
                </tr>
                <tr>
                  <th className="w-25">Result</th>
                  <td className="w-75">{form.result}</td>
                </tr>
                <tr>
                  <th className="w-25">Conclusion</th>
                  <td className="w-75">{form.conclusion}</td>
                </tr>
                <tr>
                  <th className="w-25">Keywords</th>
                  <td className="w-75">{form.keywords}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(state => ({ state }))(EditorAbstractDetailTable)
